<template>
    <div>
        helloworld
    </div>
</template>

<script>
    export default {
        created(){
            
        }
    }
</script>

<style scoped>

</style>